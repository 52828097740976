import { Dispatch, SetStateAction } from 'react';
import { animateScroll as scroll, Events } from 'react-scroll';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Meta } from '@app/api/pagination';

import { color as themeColors } from '@app/themes/mubi-theme';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import { ButtonArrowIcon } from '@app/components/icons/ArrowIcons';
import PaginationNumbers from '@app/components/PaginationNumbers';

const disabledStyles = css`
  opacity: 0.5;
`;

type ContainerProps = {
  isDisabled: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isDisabled && disabledStyles}
`;

const PrevLink = styled(UnstyledButton)`
  margin-right: 12px;
  cursor: pointer;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-right: 25px;
  }
`;

const NextLink = styled(UnstyledButton)`
  margin-left: 15px;
  cursor: pointer;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-left: 25px;
  }
`;

type PaginationProps = {
  getNextPage: () => void;
  getPrevPage: () => void;
  goToPage: (pageNum: number) => void;
  pageInfo: Meta;
  isDisabled?: boolean;
};

const Pagination = ({
  getNextPage,
  getPrevPage,
  goToPage,
  pageInfo,
  isDisabled = false,
}: PaginationProps) => {
  const totalPagesNum = pageInfo?.total_pages ?? 0;

  if (totalPagesNum === 1) {
    return null;
  }

  const currentPageNumber = pageInfo?.current_page ?? 0;
  const isLastPage = currentPageNumber === totalPagesNum;
  const isFirstPage = currentPageNumber === 1;

  const prevArrowColor = isFirstPage
    ? themeColors.midGray
    : themeColors.darkText;

  const nextArrowColor = isLastPage
    ? themeColors.midGray
    : themeColors.darkText;

  const doGetNextPage = () => {
    if (!isDisabled && !isLastPage) {
      getNextPage();
    }
  };

  const doGetPrevPage = () => {
    if (!isDisabled && !isFirstPage) {
      getPrevPage();
    }
  };

  const doGoToPage = (pageNum: number, isPageLinkDisabled: boolean) => {
    if (!isPageLinkDisabled) {
      goToPage(pageNum);
    }
  };

  return (
    <Container isDisabled={isDisabled}>
      <PrevLink onClick={doGetPrevPage}>
        <ButtonArrowIcon width="10px" direction="left" color={prevArrowColor} />
      </PrevLink>
      <PaginationNumbers
        goToPage={doGoToPage}
        pageInfo={pageInfo}
        isDisabled={isDisabled}
      />
      <NextLink onClick={doGetNextPage}>
        <ButtonArrowIcon width="10px" color={nextArrowColor} />
      </NextLink>
    </Container>
  );
};

export const handleChangingPage = (
  setIsChangingPage: Dispatch<SetStateAction<boolean>>,
) => {
  setIsChangingPage(true);
  Events.scrollEvent.register('end', () => {
    Events.scrollEvent.remove('end');
    setIsChangingPage(false);
  });
  scroll.scrollToTop({
    duration: 300,
  });
};

export default Pagination;
