import useTranslation from 'next-translate/useTranslation';
import parseISO from 'date-fns/parseISO';
import styled from '@emotion/styled';

import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';

import { formatDate } from '@app/services/date-format';

import Link from '@app/components/Link';
import NotebookAuthorLinks from '@app/components/notebook/NotebookAuthorLinks';
import NotebookCommentCount from '@app/components/notebook/NotebookCommentCount';
import ResponsiveBackgroundImage from '@app/components/responsive-images/ResponsiveBackgroundImage';

const MAX_TITLE_LENGTH = 120;

const NotebookIndexItem = ({ post }: { post: NotebookPost }) => {
  const { lang } = useTranslation();
  const authors = post?.authors ?? [];
  const commentCount = post?.comments?.totalCount || post?.comment_count || 0;

  let postTitle = post.title;
  if (postTitle?.length > MAX_TITLE_LENGTH) {
    postTitle = `${postTitle.substring(0, MAX_TITLE_LENGTH)}...`;
  }

  const formattedDate = formatDate(
    parseISO(post.published_at),
    'dd MMM yyyy',
    lang,
  );

  return (
    <Container>
      <ImageLink href={`/notebook/posts/${post.slug}`}>
        <ItemImage>
          <ResponsiveBackgroundImage
            imageUrl={
              post.spotlight_square_image_url || post.spotlight_image_url
            }
            imageHeight="100%"
            imageWidth="quarter"
          />
        </ItemImage>
        <DisplayType>{post.display_type}</DisplayType>
      </ImageLink>

      <ItemContent>
        <ContentLink href={`/notebook/posts/${post.slug}`}>
          {postTitle}
        </ContentLink>

        <NotebookAuthorLinks authors={authors} fontColor="midGray" />
        <ItemFooter>
          <ItemTime>
            <TimeMeta dateTime={post.published_at}>{formattedDate}</TimeMeta>
          </ItemTime>
          {commentCount > 0 && (
            <NotebookCommentCount post={post} iconFilled size="small" />
          )}
        </ItemFooter>
      </ItemContent>
    </Container>
  );
};

const Container = styled.div`
  height: 177px;
  width: 100%;
`;

const ImageLink = styled(Link)`
  position: relative;
  width: 50%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
`;

const ItemImage = styled.div`
  width: 100%;
  height: 100%;

  background-color: #111;
  background-position: 50% 50%;

  &:after {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.375) 0%,
      transparent 50%
    );
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const DisplayType = styled.div`
  color: white;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  left: 14px;
  top: 11px;
`;

const ItemContent = styled.div`
  width: 50%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-color: white;
  padding: 12px;
  position: relative;
`;

const ContentLink = styled(Link)`
  max-height: calc(100% - 40px);
  overflow: hidden;
  font-family: ${props => props.theme.font.body};
  color: ${props => props.theme.color.darkText};
  font-weight: bold;
  letter-spacing: -0.2px;
  display: block;

  &:visited {
    color: ${props => props.theme.color.darkText};
  }
  &:hover {
    color: ${props => props.theme.color.darkText};
  }
`;

const ItemFooter = styled.div`
  width: calc(100% - 25px);
  font-family: ${props => props.theme.font.brand};
  color: ${props => props.theme.color.midGray};
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const ItemTime = styled.div``;

const TimeMeta = styled.time``;

export default NotebookIndexItem;
