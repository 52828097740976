import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Meta } from '@app/api/pagination';

import UnstyledButton from '@app/components/buttons/UnstyledButton';

type PaginationNumbersProps = {
  goToPage: (pageNum: number, isDisabled: boolean) => void;
  pageInfo: Meta;
  isDisabled?: boolean;
};

const PaginationNumbers = ({
  goToPage,
  pageInfo,
  isDisabled = false,
}: PaginationNumbersProps) => {
  const [firstSectionPageNumbers, setFirstSectionPageNumbers] = useState([]);
  const [middleSectionPageNumbers, setMiddleSectionPageNumbers] = useState([]);
  const [finalSectionPageNumbers, setFinalSectionPageNumbers] = useState([]);

  const currentPageNumber = pageInfo?.current_page;
  const numTotalPages = pageInfo?.total_pages;

  const isCurrentPageNumber = (pageNum: number) =>
    pageInfo?.current_page === pageNum;

  const getPageNumberLink = (pageNum: number) => (
    <PageNumberLink
      isCurrentPage={isCurrentPageNumber(pageNum)}
      key={`page-num-${pageNum}`}
      onClick={() => goToPage(pageNum, isDisabled)}
    >
      {pageNum}
    </PageNumberLink>
  );

  const getFirstSectionPageNumbers = () => {
    const pageNumbers = [];

    if (currentPageNumber < 3) {
      for (let i = 1; i <= numTotalPages && i < 7; i += 1) {
        pageNumbers.push(getPageNumberLink(i));
      }
    } else if (currentPageNumber < 7) {
      for (
        let i = 1;
        i <= numTotalPages && i <= currentPageNumber + 2;
        i += 1
      ) {
        pageNumbers.push(getPageNumberLink(i));
      }
    } else {
      pageNumbers.push(getPageNumberLink(1));
    }

    return pageNumbers;
  };

  const getMiddleSectionPageNumbers = () => {
    const pageNumbers = [];

    if (numTotalPages < 7) {
      // No middle section
    } else if (
      currentPageNumber >= 7 &&
      currentPageNumber <= numTotalPages - 6
    ) {
      for (let i = currentPageNumber - 2; i <= currentPageNumber + 2; i += 1) {
        pageNumbers.push(getPageNumberLink(i));
      }
    } else if (currentPageNumber > numTotalPages - 6) {
      // No middle section
    }

    return pageNumbers;
  };

  const getFinalSectionPageNumbers = () => {
    const pageNumbers = [];

    if (numTotalPages < 7) {
      // No last section
    } else if (currentPageNumber < 7) {
      pageNumbers.push(getPageNumberLink(numTotalPages));
    } else if (
      currentPageNumber >= 7 &&
      currentPageNumber <= numTotalPages - 6
    ) {
      pageNumbers.push(getPageNumberLink(numTotalPages));
    } else if (currentPageNumber > numTotalPages - 7) {
      for (let i = numTotalPages - 6; i <= numTotalPages; i += 1) {
        pageNumbers.push(getPageNumberLink(i));
      }
    }

    return pageNumbers;
  };

  const setPageSections = () => {
    setFirstSectionPageNumbers(getFirstSectionPageNumbers());
    setMiddleSectionPageNumbers(getMiddleSectionPageNumbers());
    setFinalSectionPageNumbers(getFinalSectionPageNumbers());
  };

  useEffect(() => {
    setPageSections();
  }, [currentPageNumber, numTotalPages, isDisabled]);

  let showFirstSectionDivider = false;
  if (
    firstSectionPageNumbers.length > 0 &&
    (middleSectionPageNumbers.length > 0 || finalSectionPageNumbers.length > 0)
  ) {
    showFirstSectionDivider = true;
  }

  let showSecondSectionDivider = false;
  if (
    firstSectionPageNumbers.length > 0 &&
    middleSectionPageNumbers.length > 0 &&
    finalSectionPageNumbers.length > 0
  ) {
    showSecondSectionDivider = true;
  }

  return (
    <PageNumbers>
      {firstSectionPageNumbers}
      {showFirstSectionDivider && <SectionDivider />}
      {middleSectionPageNumbers}
      {showSecondSectionDivider && <SectionDivider />}
      {finalSectionPageNumbers}
    </PageNumbers>
  );
};

export default PaginationNumbers;

const PageNumbers = styled.div`
  display: flex;
  align-items: center;
`;

const currentPageStyle = css`
  background-color: white;
  font-style: italic;
`;

const pageStyle = css`
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;

const PageNumberLink = styled(UnstyledButton)`
  display: inline-block;
  width: 25px;
  padding: 7px 0;
  text-align: center;
  margin: 0 5px;
  font-size: 13px;
  color: ${props => props.theme.color.lightText};
  ${props => (props.isCurrentPage ? currentPageStyle : pageStyle)}

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 16px;
    width: 28px;
    margin: 0 10px;
  }
`;

const SectionDivider = styled.div`
  &:not(:last-child):after {
    content: '—';
    padding-left: 2px;
    padding-right: 2px;

    @media (min-width: ${props => props.theme.mqNew.tablet}) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
`;
